window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min');

// Document Ready
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('a[href]').forEach(link => {
    link.addEventListener('click', () => {
      let url = link.getAttribute('href');
      let title = link.getAttribute('title') || '';
      let fromPage = window.location.href;

      // Daten an PHP senden
      fetch('/ajax/tracker.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&fromPage=${encodeURIComponent(fromPage)}`
      });
    });
  });
});